
import {
  DadosFaturamento,
  Endereco,
  getInstance,
  Pedido,
  PedidoPaService,
} from "@/services/PedidoService";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import Status from "./Status.vue";
import StatusControl from "./StatusControl.vue";
import { useShowUser } from "@/components/UserView.vue";
import { store } from "@/store";

export default defineComponent({
  components: { Status, StatusControl },
  setup() {
    const userView = useShowUser();
    const loading = ref(true);
    const pedido = ref<Pedido>();

    const route = useRoute();

    async function load() {
      loading.value = true;
      try {
        const svc = route.query?.pa ? new PedidoPaService() : getInstance();
        pedido.value = await svc.getById(route.params.id as unknown as number);
      } finally {
        loading.value = false;
      }
    }

    const loadingDadosFaturamento = ref(false);
    const dadosFaturamento = ref<DadosFaturamento>();
    async function loadDadosFaturamento() {
      if (!store.state.user?.admin) {
        return;
      }
      loadingDadosFaturamento.value = true;
      try {
        const svc = new PedidoPaService();
        dadosFaturamento.value = await svc.getDadosFaturamento(route.params.id as unknown as number);
      } finally {
        loadingDadosFaturamento.value = false;
      }
    }

    onMounted(async () => {
      await load();
      await loadDadosFaturamento();
    });

    const endereco = computed((): Endereco => {
      if (pedido.value?.enderecoEntrega) {
        return pedido.value?.enderecoEntrega;
      }

      // TODO: remover
      if (pedido.value?.endereco) {
        return pedido.value.endereco;
      }
      const p = pedido.value?.user?.pessoa;
      return {
        tipo: null!,
        cep: p?.cep,
        logradouro: p?.logradouro,
        enderecoNumero: p?.enderecoNumero,
        complemento: p?.complemento,
        referencia: p?.referencia,
        bairro: p?.bairro,
        cidade: p?.cidade,
        cidadeIbge: null!,
        uf: p?.uf,
        contato: pedido.value?.user?.name,
        longitude: null!,
        latitude: null!,
      };
    });

    const entrega = computed(() => {
      return pedido.value?.entregas?.[0];
    });

    const categorias = computed(() => {
      if (!pedido.value || !pedido.value.itens) {
        return {};
      }
      let categorias: any = {};
      for (const i of pedido.value.itens) {
        if (!categorias[i.item.categoria.nome]) {
          categorias[i.item.categoria.nome] = i.qtd;
          continue;
        }
        categorias[i.item.categoria.nome] += i.qtd;
      }
      return categorias;
    });

    const isCompraNoPa = computed(() => {
      return (
        pedido.value?.pontoApoio &&
        !["pa", "pa_ativacao"].includes(pedido.value?.tipo || "")
      );
    });

    const showCtrStatusAdmin = computed(() => {
      return (
        !pedido.value?.pontoApoio ||
        ["pa", "pa_ativacao"].includes(pedido.value?.tipo || "")
      );
    });

    const localCompra = computed(() => {
      return pedido.value?.pontoApoio &&
        !["pa", "pa_ativacao"].includes(pedido.value?.tipo || "")
        ? `PA - ${pedido.value?.pontoApoio?.apelido}`
        : "EMPRESA";
    });

    return {
      userView,
      categorias,
      pedido,
      loading,
      showCtrStatusAdmin,
      isCompraNoPa,
      localCompra,
      load,
      endereco,
      entrega,
      showCliente() {
        pedido.value?.user?.id && userView.show(pedido.value.user.id);
      },
      getTipoPagamento() {
        for (const p of pedido.value?.pagamentos || []) {
          if (p.status === "concluido") {
            return p.tipoPagamento.descricao;
          }
        }
        // Algumas vezes o tipo de pagamento pix não está ficando concluido
        return 'PIX';
      },
      loadingDadosFaturamento,
      dadosFaturamento
    };
  },
});
