
import {
  Endereco,
  getInstance,
  Pedido,
  PedidoPaService,
} from "@/services/PedidoService";
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import { useRoute } from "vue-router";
import Status from "./Status.vue";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useStore } from "@/store";

export default defineComponent({
  components: { Status },
  emits: ["change"],
  props: {
    pedido: {
      type: Object as PropType<Pedido>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const saving = ref(false);
    const toast = useToast();
    const confirm = useConfirm();
    const codRastreio = ref("");
    const freteReal = ref("");
    const store = useStore();

    async function doChange(status: string) {
      saving.value = true;
      try {
        const svc = store.state.user?.temPontoApoio
          ? new PedidoPaService()
          : getInstance();
        await svc.changeStatus(props.pedido.id, status, null!, {
          codRastreio: codRastreio.value,
          freteReal: freteReal.value,
        });
        toast.add({
          severity: "success",
          summary: "Status do pedido foi atualizado",
          life: 2_000,
        });
        emit("change");
      } finally {
        saving.value = false;
      }
    }

    const entrega = computed(() => {
      return props.pedido.entregas?.[0];
    });

    return {
      entrega,
      codRastreio,
      freteReal,
      saving,
      changeStatus(status: string) {
        confirm.require({
          message: "Tem certeza que deseja mudar o status do pedido?",
          header: "Confirmação",
          icon: "pi pi-exclamation-triangle",
          async accept() {
            doChange(status);
          },
        });
      },
    };
  },
});
