<template>
  <div class="p-grid card">
    <div class="p-col-12 p-d-flex p-jc-center" v-if="loading">
      <p-progress-spinner style="width: 50px; height: 50px" />
    </div>
    <template v-else-if="pedido">
      <h3 class="p-col-12">Pedido Nº {{ pedido.id }}</h3>

      <template v-if="$store.state.user?.admin">
        <p-card class="p-col-12 p-md-6" v-if="!isCompraNoPa">
          <template #title>Embalagens</template>
          <template #content>
            <div v-if="!pedido.embalagens?.length">Sem registro de embalagens</div>
            <div
              v-else
              v-for="(e, i) in pedido.embalagens"
              :key="i"
            >{{ e.qtd }}x - {{ e.embalagem.descricao }}</div>
          </template>
        </p-card>

        <StatusControl
          :pedido="pedido"
          @change="load"
          v-if="showCtrStatusAdmin"
        />
      </template>

      <template v-if="$store.state.user?.temPontoApoio">
        <StatusControl :pedido="pedido" @change="load" />
      </template>

      <div class="p-col-12">
        <div class="info" v-if="pedido.createdAt">
          <span>Data:</span>
          <span>{{ new Date(pedido.createdAt).toLocaleString() }}</span>
        </div>
        <div class="info" v-if="localCompra">
          <span>Local da Compra:</span>
          <span>
            <b>{{ localCompra }}</b>
          </span>
        </div>
        <template v-if="pedido.user">
          <div class="info">
            <span>Cliente:</span>
            <span>
              <a style="cursor: pointer" @click="showCliente">
                {{
                pedido.user.name
                }}
              </a>
            </span>
          </div>
          <div class="info">
            <span>CPF/CNPJ:</span>
            <span>{{ pedido.user.pessoa.documento }}</span>
          </div>
          <div class="info">
            <span>RG/IE:</span>
            <span>{{ pedido.user.pessoa.rgInsc }}</span>
          </div>
        </template>
        <div class="info">
          <span>Status:</span>
          <Status :value="pedido.status" />
        </div>
        <div class="info" v-if="pedido.dataPagamento">
          <span>Forma Pagamento:</span>
          <span style="font-weight: bold; text-transform: uppercase">
            {{
            getTipoPagamento()
            }}
          </span>
        </div>
        <div class="info">
          <span>Valor dos produtos:</span>
          <span>{{ $formatCurrency(pedido.totalItens) }}</span>
        </div>
        <div class="info" v-if="pedido.frete > 0">
          <span>Frete:</span>
          <span>{{ $formatCurrency(pedido.frete) }}</span>
        </div>
        <div class="info" v-if="pedido.desconto > 0">
          <span>Desconto:</span>
          <span>{{ $formatCurrency(pedido.desconto) }}</span>
        </div>
        <div class="info">
          <span>Total:</span>
          <span>{{ $formatCurrency(pedido.total) }}</span>
        </div>
        <div class="info" v-if="pedido.observacao">
          <span>Observação:</span>
          <span style="font-weight: bold; color: red">
            {{
            pedido.observacao
            }}
          </span>
        </div>
      </div>

      <section v-if="$store.state.user?.admin" class="p-col-12">
        <h4>Dados Faturamento</h4>
        <p-divider />

        <template v-if="loadingDadosFaturamento">
          <p-progress-spinner style="width: 50px; height: 50px" />
        </template>
        <template v-else-if="dadosFaturamento">
          <div class="info">
            <span>CPF/CNPJ:</span>
            <span>{{ dadosFaturamento.documento }}</span>
          </div>
          <div class="info">
            <span>RG/IE:</span>
            <span>{{ dadosFaturamento.rgInsc }}</span>
          </div>
          <div class="info">
            <span>Telefone:</span>
            <span>{{ dadosFaturamento.telefone }}</span>
          </div>
          <div class="info">
            <span>E-mail:</span>
            <span>{{ dadosFaturamento.email }}</span>
          </div>
          <div>
            {{ dadosFaturamento.logradouro }},
            {{ dadosFaturamento.enderecoNumero }}
          </div>
          <div
            v-if="dadosFaturamento.complemento"
          >{{ dadosFaturamento.complemento }}</div>
          <div
            v-if="dadosFaturamento.referencia"
          >{{ dadosFaturamento.referencia }}</div>
          <div>{{ dadosFaturamento.bairro }}</div>
          <div>{{ dadosFaturamento.cep }} {{ dadosFaturamento.cidade }} - {{ dadosFaturamento.uf }}</div>
        </template>
      </section>

      <section v-if="entrega" class="p-col-12">
        <h4>Forma de Entrega</h4>
        <p-divider />

        <div class="p-mb-2" style="text-transform: uppercase">
          <b>{{ entrega.servico }}</b>
        </div>

        <div v-if="!entrega.retirarLocal">
          <div
            v-if="pedido.codigoRastreamento"
          >Código de rastreamento: {{ pedido.codigoRastreamento }}</div>
          <div>
            <b>{{ endereco.contato }}</b>
          </div>
          <div>
            {{ endereco.logradouro }},
            {{ endereco.enderecoNumero }}
          </div>
          <div v-if="endereco.complemento">{{ endereco.complemento }}</div>
          <div v-if="endereco.referencia">{{ endereco.referencia }}</div>
          <div>{{ endereco.bairro }}</div>
          <div>{{ endereco.cep }} {{ endereco.cidade }} - {{ endereco.uf }}</div>
          <div
            v-if="pedido.user?.pessoa.telefone"
          >{{ pedido.user?.pessoa.telefone }}</div>
        </div>
      </section>

      <section v-if="pedido.historico" class="p-col-12">
        <h4>Histórico</h4>
        <p-divider />
        <p-timeline :value="pedido.historico">
          <template #opposite="slotProps">
            <small
              class="p-text-secondary"
            >{{ new Date(slotProps.item.createdAt).toLocaleString() }}</small>
          </template>
          <template #content="slotProps">
            {{ slotProps.item.status }}
            <span
              v-if="slotProps.item.mensagem"
            >- {{ slotProps.item.mensagem }}</span>
          </template>
        </p-timeline>
      </section>

      <section class="p-col-12">
        <h4>Produtos</h4>
        <p-divider />

        <template v-if="pedido.itens">
          <div v-for="i in pedido.itens" :key="i.id">
            <div>{{ i.item.nome }}</div>
            <div>
              {{ i.qtd }} x {{ $formatNumber(i.valor) }} =
              {{ $formatCurrency(i.total) }}
            </div>
            <p-divider />
          </div>
        </template>

        <template v-if="!isCompraNoPa">
          <div v-if="pedido.freteCompensado" style="color: red">
            {{ $formatNumber((pedido.fretecompValorVenda || 0) / 40, 0) }}
            x PERF. DE 15 ML P/ COMPENSAR
            {{ $formatCurrency(pedido.frete) }} DE FRETE
          </div>

          <div v-if="pedido.totalItens >= 75" style="color: red">
            {{ $formatNumber(Math.floor(pedido.totalItens / 75) * 1, 0) }}
            x SACOLINHAS DE CORTESIA
          </div>

          <p-divider v-if="pedido.freteCompensado || pedido.totalItens >= 100" />
        </template>
      </section>

      <section class="p-col-12">
        <h4>Quantidade por Categorias</h4>
        <p-divider />

        <div
          v-for="(k, i) in Object.keys(categorias)"
          :key="i"
        >{{ k }} = {{ categorias[k] }}</div>
      </section>
    </template>
  </div>

  <x-user-view
    v-model:visible="userView.visible"
    :title="userView.title"
    :id="userView.id"
  ></x-user-view>
</template>

<script lang="ts">
import {
  DadosFaturamento,
  Endereco,
  getInstance,
  Pedido,
  PedidoPaService,
} from "@/services/PedidoService";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import Status from "./Status.vue";
import StatusControl from "./StatusControl.vue";
import { useShowUser } from "@/components/UserView.vue";
import { store } from "@/store";

export default defineComponent({
  components: { Status, StatusControl },
  setup() {
    const userView = useShowUser();
    const loading = ref(true);
    const pedido = ref<Pedido>();

    const route = useRoute();

    async function load() {
      loading.value = true;
      try {
        const svc = route.query?.pa ? new PedidoPaService() : getInstance();
        pedido.value = await svc.getById(route.params.id as unknown as number);
      } finally {
        loading.value = false;
      }
    }

    const loadingDadosFaturamento = ref(false);
    const dadosFaturamento = ref<DadosFaturamento>();
    async function loadDadosFaturamento() {
      if (!store.state.user?.admin) {
        return;
      }
      loadingDadosFaturamento.value = true;
      try {
        const svc = new PedidoPaService();
        dadosFaturamento.value = await svc.getDadosFaturamento(route.params.id as unknown as number);
      } finally {
        loadingDadosFaturamento.value = false;
      }
    }

    onMounted(async () => {
      await load();
      await loadDadosFaturamento();
    });

    const endereco = computed((): Endereco => {
      if (pedido.value?.enderecoEntrega) {
        return pedido.value?.enderecoEntrega;
      }

      // TODO: remover
      if (pedido.value?.endereco) {
        return pedido.value.endereco;
      }
      const p = pedido.value?.user?.pessoa;
      return {
        tipo: null!,
        cep: p?.cep,
        logradouro: p?.logradouro,
        enderecoNumero: p?.enderecoNumero,
        complemento: p?.complemento,
        referencia: p?.referencia,
        bairro: p?.bairro,
        cidade: p?.cidade,
        cidadeIbge: null!,
        uf: p?.uf,
        contato: pedido.value?.user?.name,
        longitude: null!,
        latitude: null!,
      };
    });

    const entrega = computed(() => {
      return pedido.value?.entregas?.[0];
    });

    const categorias = computed(() => {
      if (!pedido.value || !pedido.value.itens) {
        return {};
      }
      let categorias: any = {};
      for (const i of pedido.value.itens) {
        if (!categorias[i.item.categoria.nome]) {
          categorias[i.item.categoria.nome] = i.qtd;
          continue;
        }
        categorias[i.item.categoria.nome] += i.qtd;
      }
      return categorias;
    });

    const isCompraNoPa = computed(() => {
      return (
        pedido.value?.pontoApoio &&
        !["pa", "pa_ativacao"].includes(pedido.value?.tipo || "")
      );
    });

    const showCtrStatusAdmin = computed(() => {
      return (
        !pedido.value?.pontoApoio ||
        ["pa", "pa_ativacao"].includes(pedido.value?.tipo || "")
      );
    });

    const localCompra = computed(() => {
      return pedido.value?.pontoApoio &&
        !["pa", "pa_ativacao"].includes(pedido.value?.tipo || "")
        ? `PA - ${pedido.value?.pontoApoio?.apelido}`
        : "EMPRESA";
    });

    return {
      userView,
      categorias,
      pedido,
      loading,
      showCtrStatusAdmin,
      isCompraNoPa,
      localCompra,
      load,
      endereco,
      entrega,
      showCliente() {
        pedido.value?.user?.id && userView.show(pedido.value.user.id);
      },
      getTipoPagamento() {
        for (const p of pedido.value?.pagamentos || []) {
          if (p.status === "concluido") {
            return p.tipoPagamento.descricao;
          }
        }
        // Algumas vezes o tipo de pagamento pix não está ficando concluido
        return 'PIX';
      },
      loadingDadosFaturamento,
      dadosFaturamento
    };
  },
});
</script>

<style scoped lang="scss">
section {
  margin-bottom: 1.5em;
}
.info {
  margin: 5px 0;
  span:first-child {
    margin-right: 5px;
  }
}

::v-deep(.p-timeline-event) {
  min-height: 40px;
}
</style>
