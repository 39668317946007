<template>
  <span :class="'pedido-status status-' + value.toLowerCase()">{{
    value
  }}</span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },
  },
});
</script>

<style>
.pedido-status {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.status-pendente {
  background: #e0aa15b0;
  color: #2c2614;
}
.status-pagto_pendente {
  background: #e0aa15b0;
  color: #2c2614;
}
.status-pago {
  background: #47863ab0;
  color: #fffdf6;
}
.status-cancelado {
  background: #ec1de0;
  color: #fffdf6;
}
</style>